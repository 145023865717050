import { clearAllBodyScrollLocks, disableBodyScroll } from 'body-scroll-lock';
import { ProfilePartsFragment } from 'generated/graphql';
import { useGlobalModalContext } from 'hooks/modals/useGlobalModal';
import { useAdvancedRoles } from 'hooks/useAdvancedRoles';
import { useAuthContext } from 'hooks/useAuth';
import Link from 'next/link';
import { useEffect, useRef, useState } from 'react';
import { CSSTransition } from 'react-transition-group';
import { AvatarLink, ButtonSimple, Divider } from 'ui/generic';
import {
  CloseIcon,
  GoalDisplayIcon,
  InviteDisplayIcon,
  LibrarianDisplayIcon,
  PatronDisplayIcon,
  SettingsDisplayIcon,
  UserDisplayIcon,
} from 'ui/icons';
import { routes } from 'utils/routes';
import Portal from '../../components/layout/Portal';
import styles from './ExtendedNavigation.module.scss';

type Props = {
  profile: ProfilePartsFragment;
  close: () => void;
  openFeedbackModal: () => void;
};

const ExtendedNavigation = ({ profile, close, openFeedbackModal }: Props): JSX.Element => {
  const [showMenu, setShowMenu] = useState(false);
  const { logout } = useAuthContext();
  const scrollable = useRef<HTMLDivElement>(null);
  const { setSettingsModal } = useGlobalModalContext();
  const { isLibrarian, getLibrarianUrl } = useAdvancedRoles();
  useEffect(() => (scrollable.current ? setShowMenu(true) : setShowMenu(false)), [scrollable]);

  useEffect(() => {
    if (!scrollable.current) return;
    disableBodyScroll(scrollable.current);
    return () => {
      clearAllBodyScrollLocks();
      close();
    };
  }, []);

  return (
    <Portal>
      <CSSTransition
        in={showMenu}
        timeout={300}
        classNames={{
          enter: styles.fadeEnter,
          enterActive: styles.fadeEnterActive,
          exit: styles.fadeExit,
          exitActive: styles.fadeExitActive,
        }}
        onExited={() => close()}
      >
        <div
          onClick={(e) => {
            e.stopPropagation();
          }}
          className={styles.container}
        >
          <div
            className={styles.backdrop}
            onClick={(e) => {
              e.preventDefault();
              e.stopPropagation();
              setShowMenu(false);
            }}
          >
            <CSSTransition
              in={showMenu}
              timeout={300}
              classNames={{
                enter: styles.optionsEnter,
                enterActive: styles.optionsEnterActive,
                exit: styles.optionsExit,
                exitActive: styles.optionsExitActive,
              }}
            >
              <div
                className={styles.inner}
                onClick={(e) => {
                  e.stopPropagation();
                }}
                ref={scrollable}
              >
                <div className={styles.profileDetails}>
                  <div className={styles.avatarArea}>
                    <AvatarLink width={64} profile={profile} />
                    <div className={styles.closeButton}>
                      <ButtonSimple variant="bold" onClick={() => setShowMenu(false)}>
                        <CloseIcon />
                      </ButtonSimple>
                    </div>
                  </div>
                  <Link href={`/${profile.handle}`}>
                    <a>
                      <div className={styles.name}>
                        <h2 className="uiNav">{profile.name}</h2>@{profile.handle}
                      </div>
                    </a>
                  </Link>
                </div>
                <div>
                  <div className={styles.largeLinks}>
                    <ul>
                      <li>
                        <UserDisplayIcon />
                        <Link href={`/${profile.handle}`}>
                          <a className="uiNav">View profile</a>
                        </Link>
                      </li>
                      <li>
                        <GoalDisplayIcon />
                        <Link href={routes.goals}>
                          <a className="uiNav">Goals</a>
                        </Link>
                      </li>
                      {/* <li>
                        <BlogDisplayIcon />
                        <Link href={routes.blog}>
                          <a className="uiNav">Blog</a>
                        </Link>
                      </li> */}
                      <li>
                        <InviteDisplayIcon />
                        <Link href="/invite">
                          <a className="uiNav">Invite friends</a>
                        </Link>
                      </li>

                      {isLibrarian ? (
                        <li>
                          <LibrarianDisplayIcon />
                          <Link href={getLibrarianUrl()} as={getLibrarianUrl()}>
                            <a className="uiNav">Librarian mode</a>
                          </Link>
                        </li>
                      ) : (
                        <li>
                          <LibrarianDisplayIcon />
                          <Link href={routes.librarianInfo} as={routes.librarianInfo}>
                            <a className="uiNav">Become a librarian</a>
                          </Link>
                        </li>
                      )}

                      <li>
                        <PatronDisplayIcon />
                        <Link href={routes.patrons} as={routes.patrons}>
                          <a className="uiNav">Become a Patron</a>
                        </Link>
                      </li>

                      <li>
                        <SettingsDisplayIcon />
                        <ButtonSimple
                          variant="none"
                          onClick={() => {
                            setSettingsModal(true);
                          }}
                        >
                          <a className="uiNav">Settings</a>
                        </ButtonSimple>
                      </li>
                    </ul>
                  </div>
                  <Divider />
                  <div className={styles.smallLinks}>
                    <ul>
                      <li>
                        <a href="/pages/faq">
                          <ButtonSimple variant="basic">FAQ</ButtonSimple>
                        </a>
                      </li>
                      <li>
                        <a href="/pages/changelog">
                          <ButtonSimple variant="basic">Changelog</ButtonSimple>
                        </a>
                      </li>
                      <li>
                        <a href="/feature-ideas">
                          <ButtonSimple variant="basic">Feature ideas</ButtonSimple>
                        </a>
                      </li>
                      <li>
                        <ButtonSimple variant="basic" onClick={openFeedbackModal}>
                          Feedback
                        </ButtonSimple>
                      </li>
                      <li>
                        <ButtonSimple variant="basic" onClick={logout}>
                          Logout
                        </ButtonSimple>
                      </li>
                      <li>
                        <a href="/pages/legal">
                          <ButtonSimple variant="basic">Legal</ButtonSimple>
                        </a>
                      </li>
                      <li>
                        <a href="/pages/privacy-policy">
                          <ButtonSimple variant="basic">Privacy</ButtonSimple>
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </CSSTransition>
          </div>
        </div>
      </CSSTransition>
    </Portal>
  );
};

export default ExtendedNavigation;
