import classnames from 'classnames';
import { BooksMediumList } from 'components/book/ItemMedium';
import { ReadingArea } from 'components/HomeLayout';
import HomeHeader from 'components/layout/HomeHeader';
import BookItemMedium from 'containers/BookItemMedium';
import BookItemOptions from 'containers/BookItemOptions/BookItemOptions';
import ReadingProgressDisplay from 'containers/BookItemOptions/ReadingProgress/ReadingProgressDisplay';
import BookSelector from 'containers/BookSelector';
import { useHasAnyProgress, useReadingProgressLoader } from 'dataloaders/readingProgressLoader';
import { BookPartsFragment, ReadingStatus, useMyBooksByReadingStateQuery } from 'generated/graphql';
import { useAuthContext } from 'hooks/useAuth';
import { useLongPress } from 'hooks/useLongPress';
import useUpdateReadingstate from 'hooks/useUpdateReadingstate';
import Link from 'next/link';
import { useMemo, useState } from 'react';
import { Button, ButtonSimple, Cover, EmptyState } from 'ui/generic';
import { getProgressPercentage } from 'utils/readingProgress';
import { statesData } from 'utils/readingStates';
import { routes } from 'utils/routes';
import styles from './CurrentlyReading.module.scss';

type Props = {
  readerHandle: string;
};

const CurrentlyReadingContainer = ({ readerHandle }: Props): JSX.Element | null => {
  const stateInfo = statesData[ReadingStatus.IsReading];
  const { profile: loggedInProfile } = useAuthContext();
  const {
    data: books,
    loading,
    error,
  } = useMyBooksByReadingStateQuery({
    variables: { limit: 20, offset: 0, readingStatus: ReadingStatus.IsReading },
  });

  const hasProgress = useHasAnyProgress(books?.myBooksByReadingState.map((book) => book.id) || []);

  if (loading || error || !loggedInProfile) return null;

  if (!books?.myBooksByReadingState || books.myBooksByReadingState.length === 0)
    return (
      <ReadingArea>
        <HomeHeader handle={readerHandle} text={stateInfo.title} />
        <AddBookToCurrentlyReadingButton />
      </ReadingArea>
    );

  return (
    <ReadingArea>
      <HomeHeader
        handle={readerHandle}
        text={stateInfo.title}
        bookCount={books.myBooksByReadingState.length}
      />
      <div className={styles.desktop}>
        <BooksMediumList>
          {books.myBooksByReadingState.map((book) => (
            <BookItemDesktopContainer
              key={book.id}
              profileId={loggedInProfile.id}
              readerHandle={readerHandle}
              book={book}
            />
          ))}
        </BooksMediumList>
      </div>
      <div className={styles.mobile}>
        <div className={styles.track}>
          <div className={styles.booksHolder}>
            {books.myBooksByReadingState.map((book) => (
              <CurrentlyReadingBookMobile
                key={book.id}
                book={book}
                readerHandle={readerHandle}
                hasProgress={hasProgress}
              />
            ))}
          </div>
        </div>
      </div>
    </ReadingArea>
  );
};

const CurrentlyReadingBookMobile = ({
  readerHandle,
  book,
  hasProgress,
}: {
  readerHandle: string;
  book: BookPartsFragment;
  hasProgress: boolean;
}) => {
  const [isOpen, setOpen] = useState<boolean>();
  const longPress = useLongPress(() => setOpen(!isOpen));
  const { data: progress } = useReadingProgressLoader(book.id);

  const progressPercentage = useMemo(() => {
    return progress && progress.capacity && progress.progress ? getProgressPercentage(progress) : 0;
  }, [progress]);

  return (
    <div className={styles.mobileBookContainer}>
      <Link href={routes.userOwnedBook} as={`/${readerHandle}/book/${book.slug}`}>
        <a draggable="false" {...longPress()}>
          <div className={styles.book}>
            <Cover book={book} />
            <div style={{ width: `${progressPercentage}%` }} className={styles.readingProgressBar} />
          </div>
        </a>
      </Link>

      <span
        className={classnames(styles.progress, styles.progressMobile, {
          [styles.hasProgress]: hasProgress,
        })}
      >
        {progress && hasProgress && (
          <ButtonSimple variant="accent" onClick={() => setOpen(true)}>
            <ReadingProgressDisplay shortForm readingProgress={progress} />
          </ButtonSimple>
        )}
      </span>

      {isOpen && (
        <BookItemOptions
          book={book}
          positioningStrategy="fixed"
          buttonStyle="none"
          zLayer="Dropdown"
          isOpen={isOpen}
          setOpen={setOpen}
        />
      )}
    </div>
  );
};

const AddBookToCurrentlyReadingButton = (): JSX.Element => {
  const { updateReadingState, loading } = useUpdateReadingstate();
  const { profile } = useAuthContext();

  function addToShelf(book: BookPartsFragment) {
    if (!profile || loading) return;
    updateReadingState(ReadingStatus.IsReading, book);
  }

  return (
    <BookSelector
      mobileHeaderText="Add book to your reading list"
      onSelect={(book: BookPartsFragment) => addToShelf(book)}
      renderButton={(onClick, buttonRef) => (
        <EmptyState
          actions={[
            {
              text: 'Add a book',
              button: (
                <Button variant="tertiary" mini ref={buttonRef} onClick={onClick}>
                  Add a book
                </Button>
              ),
            },
          ]}
        />
      )}
    />
  );
};

export default CurrentlyReadingContainer;

const BookItemDesktopContainer = ({
  book,
  profileId,
  readerHandle,
}: {
  book: BookPartsFragment;
  profileId: string;
  readerHandle: string;
}): JSX.Element => {
  const { data: progress } = useReadingProgressLoader(book.id);
  return (
    <BookItemMedium
      readingProgress={
        progress && (
          <ReadingProgressDisplay className={styles.progress} readingProgress={progress} shortForm />
        )
      }
      book={book}
      profileId={profileId}
      href={`/[handle]/book/[bookSlug]`}
      as={`/${readerHandle}/book/${book.slug}`}
      optionsPositioningStrategy="fixed"
      withDefaultLinks={false}
    />
  );
};
