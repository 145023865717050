import { useLongPress as useLongPressPlugin } from 'use-long-press';
import {
  CallableContextResult,
  LongPressCallback,
  LongPressResult,
  LongPressOptions,
} from 'use-long-press/dist/types';
import { sendRnMessage } from 'utils/reactNative';

export function useLongPress<
  Target = Element,
  Callback extends LongPressCallback<Target> = LongPressCallback<Target>,
  Context = unknown
>(
  callback: Callback,
  options?: LongPressOptions<Target>
): CallableContextResult<LongPressResult<Target>, Context> {
  const longPress = useLongPressPlugin(
    (event, meta) => {
      sendRnMessage('HAPTIC_IMPACT_MEDIUM');
      callback(event, meta);
    },
    {
      cancelOnMovement: 10,
      ...options,
    }
  );

  return longPress;
}
