import { clearAllBodyScrollLocks, disableBodyScroll, enableBodyScroll } from 'body-scroll-lock';
import { ConditionalWrapper } from 'components/layout/ConditionalWrapper';
import React, { useLayoutEffect, useRef, useState } from 'react';
import { Portal } from 'react-portal';
import { CSSTransition } from 'react-transition-group';
import { ButtonSimple } from 'ui/generic';
import { CloseIcon } from 'ui/icons';
import styles from './Drawer.module.scss';

type Props = {
  children: React.ReactNode;
  isOpen: boolean;
  headerText?: string;
  allowBodyScroll?: boolean; // useful in case scrolling should be managed in a child
  onClose: () => void;
  left?: React.ReactNode;
  right?: React.ReactNode;
  noPortal?: boolean;
};

export const Drawer = ({
  children,
  isOpen,
  headerText = '',
  allowBodyScroll = false,
  onClose,
  left,
  right,
  noPortal,
}: Props): JSX.Element => {
  const [showDropdown, setShowDropdown] = useState(false);

  useLayoutEffect(() => setShowDropdown(isOpen), [isOpen]);
  const dropdownScrollElement = useRef<HTMLDivElement>(null);

  useLayoutEffect(() => {
    if (allowBodyScroll) return;
    const inModal = document.querySelector('[role="modal-scroller"]');
    clearAllBodyScrollLocks();
    if (!dropdownScrollElement.current) return;
    disableBodyScroll(dropdownScrollElement.current);
    return () => {
      if (dropdownScrollElement.current) enableBodyScroll(dropdownScrollElement.current);
      if (inModal) disableBodyScroll(inModal);
    };
  }, [isOpen]);

  return (
    <CSSTransition
      in={showDropdown}
      timeout={200}
      classNames={{
        enter: styles.slideEnter,
        enterActive: styles.slideEnterActive,
        exit: styles.slideExit,
        exitActive: styles.slideExitActive,
      }}
      onExited={() => onClose()}
    >
      <ConditionalWrapper condition={!noPortal} wrapper={(children) => <Portal>{children}</Portal>}>
        <div
          data-testid="drawer"
          className={styles.container}
          onClick={(e) => {
            e.stopPropagation();
            onClose();
          }}
        >
          <div className={styles.inner} onClick={(e) => e.stopPropagation()}>
            <div className={styles.header}>
              <div className={styles.headerLeft}>
                {left === undefined && (
                  <div className={styles.headerButton}>
                    <ButtonSimple
                      onClick={() => {
                        if (dropdownScrollElement.current) enableBodyScroll(dropdownScrollElement?.current);
                        setShowDropdown(false);
                      }}
                      data-testid="drawer-close-button"
                    >
                      <CloseIcon />
                    </ButtonSimple>
                  </div>
                )}
                {left && left}
              </div>
              <div className={styles.headerText}>{headerText}</div>
              <div className={styles.headerRight}>{right && right}</div>
            </div>
            <div className={styles.content} ref={dropdownScrollElement}>
              {children}
            </div>
          </div>
        </div>
      </ConditionalWrapper>
    </CSSTransition>
  );
};
