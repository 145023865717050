import { BookPartsFragment, ReadingStatus } from 'generated/graphql';
import { useLongPress } from 'hooks/useLongPress';
import Link from 'next/link';
import { ReactNode } from 'react';
import { Cover } from 'ui/generic';
import { noop } from 'utils/noop';
import InlineBookStatus from './InlineBookStatus';
import styles from './ItemMedium.module.scss';

type ListProps = {
  children: ReactNode;
};

export const BooksMediumList = ({ children }: ListProps): JSX.Element => {
  return <div className={styles.list}>{children}</div>;
};

type InnerProps = {
  book: BookPartsFragment;
  momentsCount?: number;
  userReadingStatus?: ReadingStatus;
  readingProgress?: ReactNode;
};

const Inner = ({ book, momentsCount, userReadingStatus, readingProgress }: InnerProps) => (
  <div className={styles.inner}>
    <div className={styles.image}>
      <Cover book={book} assetSize="100" />
    </div>
    <div className={styles.info}>
      <h4>{book.title}</h4>
      <div className={styles.authors}>
        {book.authors && book.authors.map((author) => author.name).join(', ')}
      </div>
      {readingProgress && readingProgress}
    </div>
    <div className={styles.momentsCount}>
      <InlineBookStatus momentsCount={momentsCount} userReadingStatus={userReadingStatus} />
    </div>
  </div>
);

export type BookItemMediumProps = InnerProps & {
  href?: string;
  as?: string;
  controls?: ReactNode;
  withDefaultLinks?: boolean;
  userReadingStatus?: ReadingStatus;
  onLongPress?: () => void;
  readingProgress?: ReactNode; //eg Reading Progresss
};

const ItemMedium = ({
  book,
  href,
  as,
  controls = null,
  momentsCount,
  withDefaultLinks = true,
  userReadingStatus,
  readingProgress,
  onLongPress = noop,
}: BookItemMediumProps): JSX.Element => {
  const longPress = useLongPress(onLongPress);

  if (withDefaultLinks) {
    href = `/book/[bookSlug]`;
    as = `/book/${book.slug}`;
  }

  if (!href || !as) {
    return (
      <div className={styles.book} {...longPress()}>
        <Inner book={book} />
        {controls && <div className={styles.controls}>{controls}</div>}
      </div>
    );
  }

  return (
    <Link href={href} as={as}>
      <a className={styles.book} {...longPress()} draggable="false">
        <Inner
          book={book}
          momentsCount={momentsCount}
          userReadingStatus={userReadingStatus}
          readingProgress={readingProgress}
        />
        {controls && <div className={styles.controls}>{controls}</div>}
      </a>
    </Link>
  );
};

export default ItemMedium;
