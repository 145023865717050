import { useSiteConfigQuery } from 'generated/graphql';
import { useAuthContext } from './useAuth';

type ReturnType = {
  loading: boolean;
  recEngineLive: boolean;
  alphaUser: boolean;
};

export function useSiteConfig(): ReturnType {
  const { isLoading } = useAuthContext();
  const { data, loading } = useSiteConfigQuery({
    notifyOnNetworkStatusChange: true,
    skip: isLoading,
    fetchPolicy: 'cache-first',
    ssr: false,
  });

  return {
    loading,
    recEngineLive: data?.recEngineLive ?? false,
    alphaUser: data?.isAlphaUser ?? false,
  };
}
