import Metadata from 'components/Metadata';

export const IndexPageMetadata = () => {
  return (
    <Metadata
      title="Literal.club | Track your reading and discover new books"
      description="Discover your next favourite book and keep track of what you’ve read and want to read. See what your friends are reading and join book clubs to explore the ideas of the world's greatest authors."
      withoutPageTitle
    />
  );
};
