import { useAuthContext } from 'hooks/useAuth';
import { SectionHeader } from 'ui/generic';
import ExploreCallToAction from './ExploreCallToAction';
import InviteCallToAction from './InviteCallToAction';

const ExploreHomeSection = (): JSX.Element => {
  const { inviteLinks } = useAuthContext();

  return (
    <>
      <SectionHeader title="Explore" />

      {inviteLinks.length > 0 && (
        <div className="mb-6">
          <InviteCallToAction />
        </div>
      )}
      <ExploreCallToAction />
    </>
  );
};

export default ExploreHomeSection;
