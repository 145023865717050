import { useAuthContext } from 'hooks/useAuth';
import { useCookie } from 'hooks/useCookie';
import Link from 'next/link';
import { useState } from 'react';
import { Button, ButtonSimple, Tooltip } from 'ui/generic';
import { CallToAction } from 'ui/generic/CallToAction';
import { GoalIcon, PlusIcon } from 'ui/icons';
import { routes } from 'utils/routes';
import { GoalModal } from './GoalModal';

type Props = {
  variant?: 'focus' | 'icon' | 'homeComponent' | 'primary' | 'tertiary';
  buttonText?: string;
};

const CreateGoalButton = ({ variant, buttonText }: Props): JSX.Element => {
  const [hideMe, setHideMe] = useCookie('hide_create_goal', 'false', { days: 30, path: '/' });
  const { isSignedIn } = useAuthContext();
  const [showModal, setShowModal] = useState(false);

  if (!isSignedIn) {
    return (
      <Tooltip
        text="Sign up to use"
        render={(ref) => (
          <Link href={routes.register}>
            <a>
              <Button variant="focus" ref={ref}>
                Create a Reading Goal
              </Button>
            </a>
          </Link>
        )}
      />
    );
  }

  return (
    <>
      {(variant === 'focus' || variant === 'primary' || variant === 'tertiary') && (
        <Button variant={variant} mini={variant === 'tertiary'} onClick={() => setShowModal(!showModal)}>
          {buttonText}
        </Button>
      )}
      {variant === 'icon' && (
        <ButtonSimple iconOnly variant="faded" onClick={() => setShowModal(!showModal)}>
          <PlusIcon />
        </ButtonSimple>
      )}
      {variant === 'homeComponent' && hideMe !== 'true' && (
        <a onClick={() => setShowModal(!showModal)}>
          <CallToAction
            icon={<GoalIcon />}
            text="Set a reading goal"
            button={
              <ButtonSimple
                variant="faded"
                onClick={(e) => {
                  e.stopPropagation();
                  e.preventDefault();
                  setHideMe('true');
                }}
              >
                Hide
              </ButtonSimple>
            }
          />
        </a>
      )}
      {showModal && <GoalModal close={() => setShowModal(false)} />}
    </>
  );
};

export default CreateGoalButton;
