import ReadingStateIcon from 'components/library/ReadingStateIcon';
import { ReadingStatus, ShelfBookProfile, ShelfPartsFragment } from 'generated/graphql';
import { HighlightIcon, ShelfIcon, ShelvesIcon, StarEmptyIcon } from 'ui/icons';
import { statesData } from 'utils/readingStates';
import styles from './InlineBookStatus.module.scss';

type Props = {
  shelves?: ShelfPartsFragment[] | ShelfBookProfile[];
  momentsCount?: number;
  rating?: number;
  userReadingStatus?: ReadingStatus;
};

const InlineBookStatus = ({
  shelves,
  momentsCount = undefined,
  rating,
  userReadingStatus,
}: Props): JSX.Element | null => {
  if (!shelves && !momentsCount && !rating && !userReadingStatus) return null;
  return (
    <div className={styles.container}>
      {shelves && shelves.length === 1 && (
        <div className={styles.tag}>
          <ShelfIcon />
          <span className={styles.text}>{shelves.length}</span>
        </div>
      )}
      {shelves && shelves.length > 1 && (
        <div className={styles.tag}>
          <ShelvesIcon />
          <span className={styles.text}>{shelves.length}</span>
        </div>
      )}
      {!!momentsCount && momentsCount !== 0 && (
        <div className={styles.tag}>
          <HighlightIcon />
          <span className={styles.text}>{momentsCount}</span>
        </div>
      )}
      {rating && (
        <div className={styles.tag}>
          <StarEmptyIcon />
          <span className={styles.text}>{rating}</span>
        </div>
      )}
      {userReadingStatus && userReadingStatus !== 'NONE' && (
        <div className={styles.tag}>
          <ReadingStateIcon readingStatus={userReadingStatus} />
          <span className={styles.text}>{statesData[userReadingStatus].title}</span>
        </div>
      )}
    </div>
  );
};

export default InlineBookStatus;
