import { useAuthContext } from 'hooks/useAuth';
import { useCookie } from 'hooks/useCookie';
import Link from 'next/link';
import { ButtonSimple } from 'ui/generic';
import { CallToAction } from 'ui/generic/CallToAction';
import { InviteIcon } from 'ui/icons';

export const InviteCallToAction = (): JSX.Element | null => {
  const { inviteLinks } = useAuthContext();
  const [hideMe, setHideMe] = useCookie('hide_invite_box', 'false', { days: 30, path: '/' });
  const inviteLink = inviteLinks[0];
  const inviteCount = inviteLink.limit - inviteLink.usage;
  if (hideMe === 'true') return null;
  return (
    <Link href="/invite">
      <a>
        <CallToAction
          icon={<InviteIcon />}
          text={<>Invite your friends to Literal</>}
          button={
            <ButtonSimple
              variant="faded"
              onClick={(e) => {
                e.stopPropagation();
                e.preventDefault();
                setHideMe('true');
              }}
            >
              Hide
            </ButtonSimple>
          }
        />
      </a>
    </Link>
  );
};

export default InviteCallToAction;
