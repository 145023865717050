import LoadingSpinner from 'components/basics/LoadingSpinner';
import { DropdownBookItem, DropdownProfileItem } from 'components/layout/dropdownSearch/DropdownItems';
import {
  BookPartsFragment,
  ProfilePartsFragment,
  useSearchBooksQuery,
  useSearchBookV2Query,
  useSearchProfilesQuery,
} from 'generated/graphql';
import { useMyReadingStates } from 'hooks/useReadingStates';
import { useScreenSize } from 'hooks/useScreenSize';
import { useEffect, useMemo, useState } from 'react';
import { Waypoint } from 'react-waypoint';
import { UserSadIcon } from 'ui/icons';
import { ActionItem, ActionItemCustomRenderWithClick, ActionList } from 'ui/specific/actionList';
import { removeDuplicates } from 'utils/array';
import { notEmpty } from 'utils/typeGuards';

type Props = {
  query: string;
  selectItem: (item: BookPartsFragment) => void;
};

export const DropdownBookSearchResults = ({ query, selectItem }: Props): JSX.Element => {
  const { smScreen } = useScreenSize();
  const { readingStates } = useMyReadingStates();

  const [fallbackData, setFallbackData] = useState<BookPartsFragment[]>();
  const [loadingFallback, setLoadingFallback] = useState(false);

  const { data, loading } = useSearchBookV2Query({
    ssr: false,
    variables: { query },
    skip: !query,
  });

  const { refetch: fetchFallback } = useSearchBooksQuery({
    variables: { query },
    notifyOnNetworkStatusChange: true,
    skip: true,
  });

  useEffect(() => {
    setFallbackData(undefined);
  }, [data]);

  const searchResults: BookPartsFragment[] = useMemo(() => {
    if (!data?.searchBookV2) return [];
    return removeDuplicates(data.searchBookV2.concat(fallbackData?.filter(notEmpty) || []), 'id');
  }, [data, fallbackData]);

  async function onFallbackBooks() {
    if ((fallbackData && fallbackData?.length > 0) || loadingFallback) return;
    setLoadingFallback(true);
    const { data } = await fetchFallback();
    setFallbackData(data?.searchBook?.filter(notEmpty) || []);
    setLoadingFallback(false);
  }

  if (loading)
    return (
      <ActionList>
        <ActionItem item={{ render: <LoadingSpinner /> }} />
      </ActionList>
    );

  return (
    <ActionList maxHeight={!smScreen ? 345 : undefined} role="search-results">
      {searchResults && searchResults.length > 0 ? (
        <>
          {searchResults.map((item) => (
            <ActionItem
              key={`dropsearch${item.id}`}
              item={
                {
                  render: <DropdownBookItem book={item} readingStates={readingStates || []} />,
                  onClick: (e) => {
                    e.stopPropagation();
                    e.preventDefault();
                    selectItem(item);
                  },
                } as ActionItemCustomRenderWithClick
              }
            />
          ))}
          <Waypoint onEnter={onFallbackBooks} />

          {loadingFallback && <ActionItem item={{ render: <LoadingSpinner /> }} />}
        </>
      ) : (
        <CannotFindThat />
      )}
    </ActionList>
  );
};

type SearchProfileProps = {
  query: string;
  selectItem: (item: ProfilePartsFragment) => void;
  filterIds?: string[]; //pass array of profileIds to filter out results
};

export const DropdownProfileSearchResults = ({
  query,
  selectItem,
  filterIds = [],
}: SearchProfileProps): JSX.Element => {
  const { smScreen } = useScreenSize();
  const { data, loading } = useSearchProfilesQuery({
    variables: {
      query: query,
    },
  });

  const hasNoSearchResults = useMemo(
    () => (data?.searchProfiles && data.searchProfiles.length === 0) || data?.searchProfiles === undefined,
    [data]
  );

  if (loading)
    return (
      <>
        <ActionList>
          <ActionItem item={{ render: <LoadingSpinner /> }} />
        </ActionList>
      </>
    );

  return (
    <ActionList maxHeight={!smScreen ? 345 : undefined} role="search-results">
      {data?.searchProfiles &&
        data.searchProfiles.length > 0 &&
        data.searchProfiles
          .filter(notEmpty)
          .filter((item) => !filterIds.includes(item.id))
          .map((item) => (
            <ActionItem
              key={`dropsearch${item.id}`}
              item={
                {
                  render: <DropdownProfileItem profile={item} />,
                  onClick: (e) => {
                    e.stopPropagation();
                    e.preventDefault();
                    selectItem(item);
                  },
                } as ActionItemCustomRenderWithClick
              }
            />
          ))}
      {hasNoSearchResults && <CannotFindThat />}
    </ActionList>
  );
};

const CannotFindThat = (): JSX.Element => {
  return (
    <div
      className="pt-12 pb-12"
      style={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        flexDirection: 'column',
      }}
    >
      <UserSadIcon />
      <p className="mt-5">Can&#39;t find anything like that</p>
    </div>
  );
};
