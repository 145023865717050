import classNames from 'classnames';
import { PatronBadgeSmall } from 'components/badges/PatronBadge';
import ExtendedNavigation from 'containers/navigation/ExtendedNavigation';
import { useAuthContext } from 'hooks/useAuth';
import { useIsSupporter } from 'hooks/useIsSupporter';
import { useNotificationsContext } from 'hooks/useNotifications';
import Link from 'next/link';
import { useState } from 'react';
import { CSSTransition } from 'react-transition-group';
import { Avatar, ButtonSimple } from 'ui/generic';
import { MoreIcon, NotificationIcon, NotificationOnDotIcon } from 'ui/icons';
import { FeedbackModal } from '../FeedbackModal';
import styles from './MobileHomeHeader.module.scss';
import { NavChat } from './NavChat';

type Props = {
  showMobileNav: boolean;
  isMobileNavAbsolute: boolean;
};

const MobileHomeHeader = ({ showMobileNav, isMobileNavAbsolute }: Props): JSX.Element | null => {
  const { isSignedIn, profile } = useAuthContext();
  const { notificationsCount } = useNotificationsContext();
  const [extendedNavOpen, setExtendedNavOpen] = useState(false);
  const [isFeedbackModalVisible, setIsFeedbackModalVisible] = useState(false);

  const isSupporter = useIsSupporter({ profileId: profile?.id });

  const hasUnseen = notificationsCount && notificationsCount > 0;

  if (!profile || !isSignedIn) return null;

  return (
    <CSSTransition
      in={showMobileNav}
      timeout={0}
      classNames={{
        enter: styles.topDownEntered,
        enterDone: styles.topDownEntered,
        exit: styles.topDownExited,
        exitDone: styles.topDownExited,
      }}
    >
      <div className={classNames(styles.container, { [styles.absolute]: isMobileNavAbsolute })}>
        <div className={styles.leftContent}>
          <ButtonSimple onClick={() => setExtendedNavOpen(true)} variant="faded">
            <Avatar profile={profile} avatarSticker={isSupporter ? <PatronBadgeSmall noLink /> : undefined} />
            <div className="ml-2 flex">
              <MoreIcon />
            </div>
          </ButtonSimple>
        </div>
        <div className={styles.rightContent}>
          <div className="ml-4 mr-4">
            <NavChat className={classNames(styles.notificationIcon)} />
          </div>
          <Link href="/notifications">
            <a className={classNames(styles.notificationIcon, { [styles.unseen]: hasUnseen })}>
              {hasUnseen ? <NotificationOnDotIcon /> : <NotificationIcon />}
            </a>
          </Link>
        </div>
        {extendedNavOpen && (
          <ExtendedNavigation
            close={() => setExtendedNavOpen(false)}
            profile={profile}
            openFeedbackModal={() => setIsFeedbackModalVisible(true)}
          />
        )}
        {isFeedbackModalVisible && <FeedbackModal setVisible={setIsFeedbackModalVisible} />}
      </div>
    </CSSTransition>
  );
};

export default MobileHomeHeader;
