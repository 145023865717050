import BookItem, { BookItemMediumProps } from 'components/book/ItemMedium';
import BookItemOptions from 'containers/BookItemOptions/BookItemOptions';
import { useMomentCountLoader } from 'dataloaders/momentCountLoader';
import { useMyReadingStates } from 'hooks/useReadingStates';
import { ReactNode, useMemo, useState } from 'react';
import { ActionItemType } from 'ui/specific/actionList';

type Props = Omit<BookItemMediumProps, 'controls' | 'readingStates' | 'momentsCount'> & {
  profileId?: string;
  additionalOptions?: ActionItemType[];
  optionsPositioningStrategy?: 'fixed' | 'absolute';
  optionsZLayer?: 'Dropdown' | 'Header' | 'Modal';
  hasReadingStateTags?: boolean;
  readingProgress?: ReactNode;
};

const BookItemsMediumContainer = ({
  profileId,
  additionalOptions,
  optionsPositioningStrategy = 'absolute',
  hasReadingStateTags,
  readingProgress,
  ...props
}: Props): JSX.Element => {
  const [isOpenDropdown, setOpenDropdown] = useState(false);

  const { data: momentsCount } = useMomentCountLoader({ bookId: props.book.id, profileId });
  const { readingStates } = useMyReadingStates();

  const readingStatus = useMemo(
    () => readingStates && readingStates?.filter((x) => x.bookId == props.book.id)[0]?.status,
    [readingStates, props.book]
  );

  return (
    <BookItem
      readingProgress={readingProgress}
      {...props}
      onLongPress={() => setOpenDropdown(true)}
      momentsCount={momentsCount || 0}
      userReadingStatus={hasReadingStateTags && readingStatus ? readingStatus : undefined}
      controls={
        <BookItemOptions
          book={props.book}
          additionalOptions={additionalOptions}
          positioningStrategy={optionsPositioningStrategy}
          zLayer={props.optionsZLayer}
          isOpen={isOpenDropdown}
          setOpen={setOpenDropdown}
        />
      }
    />
  );
};

export default BookItemsMediumContainer;
