import { LinkSimple, SectionHeader } from 'ui/generic';
import styles from './HomeHeader.module.scss';

type Props = {
  text: string;
  handle?: string;
  bookCount?: number;
};

export const HomeHeader = ({ text, bookCount, handle }: Props) => {
  return (
    <SectionHeader
      title={text}
      containerClassName={styles.container}
      number={
        bookCount &&
        bookCount > 0 && (
          <LinkSimple
            className={styles.bookCount}
            href={`/[handle]/is-reading`}
            as={`/${handle}/is-reading`}
            variant="faded"
          >
            {`${bookCount} Book${bookCount > 1 ? 's' : ''}`}
          </LinkSimple>
        )
      }
    />
  );
};

export default HomeHeader;
