import classNames from 'classnames';
import { useEffect, useState } from 'react';
import styles from './RawHtmlContent.module.scss';

type Props = {
  html: string;
};

export const RawHtmlContent = ({ html }: Props): JSX.Element => {
  const [visible, setVisible] = useState(false);

  useEffect(() => {
    setTimeout(() => {
      setVisible(true);
      setTimeout(() => {
        window.dispatchEvent(new Event('reactpageload'));
      }, 300);
    }, 200);
  }, []);

  return (
    <div
      className={classNames(styles.container, {
        [styles.visible]: visible,
      })}
    >
      <div dangerouslySetInnerHTML={{ __html: html }} />
    </div>
  );
};
