import classNames from 'classnames';
import GoalsMini from 'components/goal/GoalMini';
import CreateGoalButton from 'containers/Goals/CreateGoalButton';
import { dayjs } from 'date-utils';
import { useMyGoalParticipationsQuery } from 'generated/graphql';
import { useAuthContext } from 'hooks/useAuth';
import Link from 'next/link';
import { useMemo } from 'react';
import styles from './GoalsHomeSection.module.scss';

const GoalsHomeSection = (): JSX.Element | null => {
  const today = useMemo(() => dayjs().endOf('day').toISOString(), []);
  const { data } = useMyGoalParticipationsQuery({
    variables: { limit: 5, offset: 0, earliestEndDate: today },
  });
  const { profile } = useAuthContext();

  const goals = data?.myGoalParticipations;

  if (!goals) return null;

  return (
    <>
      {goals.length === 0 ? (
        <div className={styles.createGoal}>
          <CreateGoalButton variant="homeComponent" />
        </div>
      ) : (
        <>
          <div className="mt-5"></div>
          <div className={styles.track}>
            <div
              className={classNames(styles.goalsHolder, {
                [styles.fullWidth]: goals.length === 1,
              })}
            >
              {goals.map((goalParticipation) => (
                <Link
                  key={goalParticipation.id}
                  href={`${profile?.handle}/goal/${goalParticipation.goal.slug}`}
                >
                  <a className={styles.goal}>
                    <GoalsMini goal={goalParticipation.goal} progress={goalParticipation.progress} />
                  </a>
                </Link>
              ))}
              <div className={styles.spacer} />
            </div>
          </div>
        </>
      )}
    </>
  );
};

export default GoalsHomeSection;
