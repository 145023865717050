import { ReactNode } from 'react';
import styles from './HomeLayout.module.scss';

type Props = {
  children: ReactNode;
};

export const GoalsArea = ({ children }: Props): JSX.Element => {
  return <div className={styles.goalsArea}>{children}</div>;
};

export const ReadingArea = ({ children }: Props): JSX.Element => {
  return <div className={styles.readingArea}>{children}</div>;
};

export const ExploreArea = ({ children }: Props): JSX.Element => {
  return <div className={styles.exploreArea}>{children}</div>;
};

export const FeedArea = ({ children }: Props): JSX.Element => {
  return <div className={styles.feedArea}>{children}</div>;
};

export const MembersArea = ({ children }: Props): JSX.Element => {
  return <div className={styles.membersArea}>{children}</div>;
};

export const MobileHeaderArea = ({ children }: Props): JSX.Element => {
  return <div className={styles.mobileHeader}>{children}</div>;
};

const HomeLayout = ({ children }: Props): JSX.Element => {
  return <div className={styles.container}>{children}</div>;
};

export default HomeLayout;
