import DataLoader from 'dataloader';
import {
  Maybe,
  MomentsCount,
  MomentsCountsDocument,
  MomentsCountsQuery,
  MomentsCountsQueryVariables,
} from 'generated/graphql';
import { useEffect, useState } from 'react';
import getApolloClient from 'utils/getApolloClient';

type Pair = {
  bookId: string;
  profileId: string;
};

export const momentCountLoader = new DataLoader(
  async (keys: readonly Pair[]): Promise<Maybe<MomentsCount>[]> => {
    const client = getApolloClient();

    const { data } = await client.query<MomentsCountsQuery, MomentsCountsQueryVariables>({
      query: MomentsCountsDocument,
      variables: { pairs: [...keys] },
      fetchPolicy: 'no-cache',
    });

    return data.momentsCounts;
  },
  {
    batch: true,
    cache: true,
    cacheKeyFn: (key: Pair): string => `momentsCount:${key.bookId}:${key.profileId}`,
  }
);

export function useMomentCountLoader({ profileId, bookId }: { profileId?: string; bookId?: string }) {
  const [data, setData] = useState<number | undefined>();

  useEffect(() => {
    if (!profileId || !bookId) return;
    momentCountLoader.load({ profileId, bookId }).then((s) => {
      setData(s?.count);
    });
  }, [profileId, bookId]);

  return {
    data,
    setData,
    refetch: async () => {
      if (!profileId || !bookId) return;
      momentCountLoader.clear({ profileId, bookId });
      const data = await momentCountLoader.load({ profileId, bookId });
      setData(data?.count);
    },
  };
}
