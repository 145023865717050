import { useRecProfilesByCommunityQuery } from 'generated/graphql';
import { useSiteConfig } from 'hooks/useSiteConfig';
import Link from 'next/link';
import { Avatar } from 'ui/generic';
import { PlusIcon } from 'ui/icons';
import styles from './ExploreCallToAction.module.scss';

const ExploreCallToAction = (): JSX.Element | null => {
  const { recEngineLive } = useSiteConfig();
  const { data } = useRecProfilesByCommunityQuery({ skip: !recEngineLive });
  const avatarSizes = [40, 36, 30, 27, 24];

  return (
    <Link href="/explore">
      <a>
        <div className={styles.container}>
          <div className={styles.left}>
            <p className={styles.headline}>Reading is better with friends</p>
            <p>
              Find people to follow &amp;
              <br />
              get book recommendations
            </p>
          </div>
          <div className={styles.right}>
            <div className={styles.avatars}>
              {data && data.recProfilesByCommunity.length > 0 ? (
                data.recProfilesByCommunity.slice(0, 5).map((profile, i) => (
                  <div className={styles.avatarHolder} key={profile.id}>
                    <Avatar
                      avatarSticker={
                        <div className={styles.sticker}>
                          <PlusIcon />
                        </div>
                      }
                      width={avatarSizes[i]}
                      profile={profile}
                    />
                  </div>
                ))
              ) : (
                <img src="/img/avatar-group-fallback.png" alt="avatars" />
              )}
            </div>
          </div>
        </div>
      </a>
    </Link>
  );
};

export default ExploreCallToAction;
