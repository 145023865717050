import Head from 'next/head';

type Props = {
  title?: string;
  description?: string;
  withoutPageTitle?: boolean;
};

const Metadata = ({ title, description, withoutPageTitle }: Props): JSX.Element => {
  return (
    <Head>
      {title && (
        <title key="title">
          {title}
          {!withoutPageTitle ? ' | Literal' : ''}
        </title>
      )}
      {description && <meta name="description" content={description} />}{' '}
    </Head>
  );
};

export default Metadata;
