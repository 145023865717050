import HomeLayout, { ExploreArea, FeedArea, GoalsArea, MobileHeaderArea } from 'components/HomeLayout';
import FooterSidebar from 'components/layout/FooterSidebar';
import { RawHtmlContent } from 'components/RawHtmlContent';
import CurrentlyReading from 'containers/Home/CurrentlyReading';
import ExploreHomeSection from 'containers/Home/ExploreHomeSection';
import GoalsHomeSection from 'containers/Home/GoalsHomeSection';
import { IndexPageMetadata } from 'containers/Home/IndexPageMetadata';
import MobileHomeHeader from 'containers/navigation/MobileHomeHeader';
import Timeline from 'containers/Timeline';
import { ProfilePartsFragment } from 'generated/graphql';
import { useOnboardingModalContext } from 'hooks/modals/useOnboardingModal';
import { ActionBlocksContextProvider } from 'hooks/useActionBlocks';
import { useAuthContext } from 'hooks/useAuth';
import { useScreenSize } from 'hooks/useScreenSize';
import { GetServerSideProps, NextPage } from 'next';
import { useRouter } from 'next/router';
import { useEffect, useRef, useState } from 'react';
import { SectionHeader } from 'ui/generic/SectionHeader';
import { routes } from 'utils/routes';
import { getMyProfile } from 'utils/ssrDataService';
import { getRemotePage, remotePages, shouldUseNocache } from 'utils/ssrRemotePages';

type Props = {
  html: string;
  profile: ProfilePartsFragment | null;
};

const IndexPage: NextPage<Props> = ({ html, profile: ssrProfile }) => {
  const router = useRouter();
  const { profile: clientSideProfile, isLoading, firstSignIn } = useAuthContext();
  const profile = isLoading ? ssrProfile : clientSideProfile;
  const { startWizard: startOnboardingWizard } = useOnboardingModalContext();
  const { smScreen } = useScreenSize();

  const scrollPosition = useRef(0);
  const [showMobileNav, setShowMobileNav] = useState(true);
  const [isMobileNavAbsolute, setIsMobileNavAbsolute] = useState(true);

  useEffect(() => {
    if (firstSignIn) {
      startOnboardingWizard();
    }
  }, [firstSignIn]);

  useEffect(() => {
    if (!smScreen) return;

    const handleScroll = () => {
      const absolute = -1 * window.scrollY > -60;
      setIsMobileNavAbsolute(absolute);
      if (absolute) {
        setShowMobileNav(-1 * window.scrollY > -60 || -1 * window.scrollY > scrollPosition.current);
      }
      if (!absolute) {
        setShowMobileNav(-1 * window.scrollY > -8 || -1 * window.scrollY > scrollPosition.current);
      }

      scrollPosition.current = -1 * window.scrollY;
    };

    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, [smScreen]);

  if (!profile)
    return (
      <>
        <IndexPageMetadata />
        <RawHtmlContent html={html} />
      </>
    );

  return (
    <HomeLayout>
      <IndexPageMetadata />

      <MobileHeaderArea>
        <MobileHomeHeader isMobileNavAbsolute={isMobileNavAbsolute} showMobileNav={showMobileNav} />
      </MobileHeaderArea>

      {profile && (
        <>
          <CurrentlyReading readerHandle={profile.handle} />
          <GoalsArea>
            <GoalsHomeSection />
          </GoalsArea>
          <ExploreArea>
            <ExploreHomeSection />
            <FooterSidebar />
          </ExploreArea>
        </>
      )}

      <FeedArea>
        <SectionHeader title="Following" />
        <ActionBlocksContextProvider>
          <Timeline
            emptyStateConfig={{
              text: 'No activities to show.\n Follow others to change that!',
              illustrationKey: 'sunglasses',
              actions: [
                {
                  text: 'Find people',
                  onClick: () => (smScreen ? router.push(routes.search) : router.push(routes.explore)),
                },
                {
                  text: 'Invite a friend',
                  onClick: () => router.push(routes.invite),
                },
              ],
            }}
          />
        </ActionBlocksContextProvider>
      </FeedArea>
    </HomeLayout>
  );
};

export const getServerSideProps: GetServerSideProps<Props> = async ({ query, req }) => {
  const noCache = shouldUseNocache(query);
  const [profile, remotePage] = await Promise.all([
    getMyProfile(req).catch(() => null),
    getRemotePage({ url: remotePages.landing, ttl: 60 * 10, noCache }),
  ]);

  return { props: { html: remotePage.html, profile: profile?.profile || null } };
};

export default IndexPage;
